import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import s from './ContactInfo.scss';

export default class ContactForm extends PureComponent {

  static propTypes = {
    image: PropTypes.object,
    textBlocks: PropTypes.array,
  }

  render() {
    const { image, textBlocks } = this.props;

    return (
      <div className={s.contactInfo}>
        {image &&
          <div className={s.contactInfo__inner}>
            <div className={s.contactInfo__pic}>
              <Image
                src={image.url}
                alt={image.alt}
                width={image.width}
                height={image.height}
                transition="scale"
              />
            </div>
          </div>
        }
        <div className={s.contactInfo__inner}>
          {textBlocks.map(block => (
            <div key={block.title} className={s.contactInfo__block}>
              {block.title &&
                <h3
                  className={s.contactInfo__title}
                  dangerouslySetInnerHTML={{ __html: block.title }} />
              }
              {block.text &&
                <div
                  className={s.contactInfo__text}
                  dangerouslySetInnerHTML={{ __html: block.text }} />
              }
            </div>
          ))}
        </div>
      </div>
    );
  }
}
