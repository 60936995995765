/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';

import Close from 'assets/svg/icons/modal-close.svg';
import Volume from 'assets/svg/icons/volume.svg';
import Fullscreen from 'assets/svg/icons/fullscreen.svg';

import PlayButton from 'components/play-button';

import s from './VideoModal.scss';

export default class VideoModal extends Component {

  static propTypes = {
    dark: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    video: PropTypes.string,
  }

  static defaultProps = {
    dark: false,
    onClose: () => {},
    open: false,
  }

  state = {
    isMuted: false,
    progress: 0,
    remaining: 0,
    isPaused: false,
    size: null,
  }

  containerRef = React.createRef();
  videoRef = React.createRef();
  trackRef = React.createRef();
  progressRef = React.createRef();
  wrapperRef = React.createRef();

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      /* eslint-disable react/prop-types */
      this.videoRef.current.pause();
      this.videoRef.current.currentTime = 0;
      /* eslint-enable react/prop-types */
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleContainerClick = (event) => {
    if (event.target === event.currentTarget) {
      this.props.onClose();
    }
  }

  handleKeyDown = (event) => {
    if (this.props.open && event.key === 'Escape') {
      this.props.onClose();
    }
  }

  onTimeUpdate = () => {
    const video = this.videoRef.current;
    const progress = this.progressRef.current;
    const track = this.trackRef.current;
    const wrapper = this.wrapperRef.current;

    if (progress && track && video && wrapper) {
      const { width: wrapperWidth } = wrapper.getBoundingClientRect();
      const width = (video.currentTime * wrapperWidth) / video.duration;

      TweenLite.set(
        progress,
        { width },
      );

      TweenLite.set(
        track,
        { left: width },
      );
    }

    const format = (time) => {
      const sec = parseInt(time % 60, 10);
      const min = parseInt((time / 60) % 60, 10);
      const seconds = sec.toString().length === 1 ? `0${sec}` : sec;
      const minutes = min.toString().length === 1 ? `0${min}` : min;

      return `${minutes}:${seconds}`;
    };

    this.setState({
      progress: format(video.currentTime),
      remaining: format(video.duration - video.currentTime),
    });
  }

  onBarClick = (e) => {
    const wrapper = this.wrapperRef.current;
    const video = this.videoRef.current;

    if (!wrapper || !video) {
      return;
    }

    const { x, width } = wrapper.getBoundingClientRect();
    const y = x + width;

    if (e.clientX > x && e.clientX < y) {
      const left = e.clientX - x;
      const newTime = (left * video.duration) / width;

      video.currentTime = newTime;
    }
  }

  togglePlay = () => {
    const video = this.videoRef.current;

    if (!video) {
      return;
    }

    if (video.paused === true) {
      video.play();
    } else {
      video.pause();
    }
  }

  toggleVolume = () => {
    const video = this.videoRef.current;

    if (!video) {
      return;
    }

    if (video.muted === false) {
      video.muted = true;
    } else {
      video.muted = false;
    }
  }

  toggleFullscreen = () => {
    const video = this.videoRef.current;

    if (!video) {
      return;
    }

    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.webkitEnterFullScreen) {
      video.webkitEnterFullScreen();
    }
  }

  onLoadedMetadata = (event) => {
    const video = event.target;

    this.setState({
      size: {
        width: video.videoWidth,
        height: video.videoHeight,
      },
    });
  }

  playVideo() {
    this.videoRef.current.play();
  }

  render() {
    const { dark, onClose, open, video } = this.props;
    const { isMuted, progress, remaining, isPaused, size } = this.state;

    return (
      <div
        className={s('videoModal', { dark, open })}
        ref={this.containerRef}
        onClick={this.handleContainerClick}
        role="presentation"
      >
        <div className={s.videoModal__container}>
          <button className={s.closeButton} type="button" onClick={onClose}>
            <Close />
          </button>

          {video && (
            <div
              className={s.video}
              style={{
                paddingBottom: size && `${(size.height / size.width) * 100}%`,
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                ref={this.videoRef}
                className={s.video__iframe}
                src={video}
                onTimeUpdate={this.onTimeUpdate}
                onLoadedMetadata={this.onLoadedMetadata}
                preload="metadata"
                onPlay={() => this.setState({ isPaused: false })}
                onPause={() => this.setState({ isPaused: true })}
                onVolumeChange={e => this.setState({ isMuted: e.target.volume === 0 })}
                playsInline
              />

              <div className={s.video__controls}>
                <div className={s.video__play}>
                  <PlayButton
                    onClick={this.togglePlay}
                    theme="dark"
                    isPaused={isPaused}
                    visible
                  />
                </div>

                <div className={s.video__buttons}>
                  <button
                    onClick={this.toggleVolume}
                    className={s(s.video__volume, { isMuted })}
                  >
                    <Volume />
                  </button>

                  <div className={s.video__range}>
                    <p className={s.video__time}>{progress}</p>

                    <button
                      className={s.video__wrapper}
                      onClick={this.onBarClick}
                      ref={this.wrapperRef}
                    >
                      <div className={s.video__bar}>
                        <div className={s.video__track} ref={this.trackRef} />
                        <div className={s.video__progress} ref={this.progressRef} />
                      </div>
                    </button>

                    <p className={s.video__time}>-{remaining}</p>
                  </div>

                  <button
                    onClick={this.toggleFullscreen}
                    className={s.video__fullscreen}
                  >
                    <Fullscreen />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
