import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './HeroVideo.scss';

export default class HeroVideo extends Component {

  static propTypes = {
    src: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    muted: PropTypes.bool,
    isPlaying: PropTypes.bool,
    onIsReady: PropTypes.func,
    playCount: PropTypes.oneOf(['once', 'twice', 'infinite']),
  }

  static defaultProps = {
    muted: true,
    playCount: 'infinite',
  }

  state = {
    playedOnce: false,
  }

  componentDidMount() {
    this.video.load();
    this.video.addEventListener('loadedmetadata', this.onCanPlay);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying) {

      if (this.props.isPlaying) {
        // linter says PropTypes issue :/
        /* eslint-disable */
        this.video.play();
      } else {
        this.video.pause();
        /* eslint-enable */
      }
    }
  }

  componentWillUnmount() {
    this.video.removeEventListener('loadedmetadata', this.onCanPlay);
  }

  onCanPlay = () => {
    const { onIsReady } = this.props;

    if (onIsReady) {
      onIsReady();
    }
  }

  onEnded = () => {
    const { playCount } = this.props;
    const { playedOnce } = this.state;

    switch (playCount) {
      case 'infinite':
        this.resetVideo();
        break;
      case 'twice':
        if (!playedOnce) {
          this.resetVideo();
        }
        break;
      case 'once':
      default:
    }

    this.setState({
      playedOnce: true,
    });
  }

  resetVideo() {
    const { video } = this;

    if (video) {
      video.currentTime = 0;
      video.play();
    }
  }

  render() {
    const { src, poster, muted, isPlaying } = this.props;

    return (
      <div className={s('heroVideo', { isPlaying })} style={{ backgroundImage: `url(${poster})` }}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={(el) => { this.video = el; }}
          muted={muted}
          playsInline
          className={s.heroVideo__video}
          poster={poster}
          src={src}
          loop
          preload="true"
          onEnded={this.onEnded}
          onTimeUpdate={this.onTimeUpdate}
        />
      </div>
    );
  }
}