import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import s from './ContactForm.scss';
import './hbspt.css';

const HBSPT_SCRIPT_ID = 'greenhouse-job-board';

export default class ContactForm extends PureComponent {

  static propTypes = {
    topOffset: PropTypes.number,
  }

  componentDidMount() {
    let script = null;

    if (!document.getElementById(HBSPT_SCRIPT_ID)) {

      script = document.createElement('script');

      script.id = HBSPT_SCRIPT_ID;
      script.src = '//js.hsforms.net/forms/v2.js';
      script.charset = 'utf-8';
      script.async = true;
      script.onload = this.onFormLoad.bind(this);

      window.requestAnimationFrame(() => {
        document.body.appendChild(script);
      });

    } else {
      this.createForm();
    }
  }

  onFormLoad() {
    this.createForm();
  }

  createForm() {
    window.hbspt.forms.create({
      target: '#hbspt-form',
      portalId: '5092845',
      css: '',
      submitButtonClass: '',
      formId: '76b0348c-ba20-4b17-8c36-52852264325a',
    });
  }

  render() {

    const marginTop = `${this.props.topOffset}px`;

    return (
      <div className={s.contactForm} style={{ marginTop }}>
        <div id="hbspt-form" className={s.contactForm__inner} />
        <a className={s.contactForm__privacy} href="/data-policy">Website Personal Data Policy</a>
      </div>
    );
  }
}
