import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';

import ContactForm from '../contact-form';
import ContactInfo from '../contact-info';

import s from './ContactSection.scss';

const OFFSET = -210;

export default class ContactSection extends PureComponent {

  static propTypes = {
    image: PropTypes.object,
    textBlocks: PropTypes.array,
  }

  render() {
    return (
      <Container>
        <div className={s.contactSection}>
          <ContactForm topOffset={OFFSET} />
          <ContactInfo image={this.props.image} textBlocks={this.props.textBlocks} />
        </div>
      </Container>
    );
  }

}
