import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import extractMeta from 'utils/extract-meta'
import { withUIContext } from 'context/ui'
import Hero from 'components/hero'
import SEO from 'components/seo'
import OfficesMap from 'components/offices-map'

import ContactSection from './components/contact-section'
import Offices from './components/offices'

class Contact extends PureComponent {
  static propTypes = {
    ui: PropTypes.object,
    data: PropTypes.object,
    location: PropTypes.string,
  }

  componentDidMount() {
    this.props.ui.setNavTheme('light')
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark')
  }

  render() {
    const { pathname } = this.props.location

    const {
      heroText,
      heroTitle,
      heroImage,
      heroTheme,
      contactBlocks,
      addresses,
    } = this.props.data.page

    const textBlocks = contactBlocks.map(element => ({
      title: element.title,
      text:
        element.childContentfulContentBlockContentRichTextNode
          .childContentfulRichText.html,
    }))

    const offices = {
      heading: 'Proxy Offices',
      iconBlocks: addresses.map(element => ({
        ctaIsButton: false,
        ctaText: element.phone,
        ctaUrl: element.phone && `tel:${element.phone.replace(/[-\+ ]/g, '')}`,
        heading: element.name,
        icon: 'None',
        image: null,
        text: {
          text: `${element.address1} \n${element.city}`,
        },
        __typename: 'ContentfulIconBlock',
      })),
    }

    return (
      <div>
        <SEO
          pathname={pathname}
          article
          htmlAttributes={{ class: 'smooth-scroll' }}
          {...extractMeta(this.props.data.page)}
        />
        <Hero
          theme={heroTheme}
          heading={heroTitle}
          text={heroText}
          poster={`http:${heroImage.file.url}`}
        />
        <ContactSection textBlocks={textBlocks} />
        <LazyLoad once offset={500}>
          <Offices heading={offices.heading} offices={offices.iconBlocks}>
            <OfficesMap image={require('assets/images/offices-map.png')} />
          </Offices>
        </LazyLoad>
      </div>
    )
  }
}
export default withUIContext(Contact)
