/* generic Hero wrapper for content layout */
/* images, videos etc should be as route-specific children? */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TimelineLite } from 'gsap';

import Circles from 'components/circles';
import Button from 'components/button';
import { Container, Row } from 'components/layout';
import Image from 'components/image';
import PlayButton from 'components/play-button';
import VideoModal from 'components/video-modal';

import HeroVideo from './HeroVideo';
import s from './Hero.scss';

export default class Hero extends Component {

  videoModalRef = React.createRef()

  state = {
    modalOpen: false, // if video
    isReady: true,
    isPlaying: false,
  }

  static propTypes = {
    animate: PropTypes.bool,
    heading: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.element,
    headingWidth: PropTypes.string,
    textWidth: PropTypes.string,
    centered: PropTypes.bool,
    theme: PropTypes.oneOf(['dark', 'light']),
    videoFile: PropTypes.string,
    videoBg: PropTypes.string,
    poster: PropTypes.string,
    image: PropTypes.object,
    cta: PropTypes.object,
    circles: PropTypes.oneOf(['Enable', 'Disable']),
    videoPlayCount: PropTypes.oneOf(['once', 'twice', 'infinite']),
    verticalTextAlign: PropTypes.oneOf(['center', 'bottom']),
    darkVideo: PropTypes.bool,
  }

  static defaultProps = {
    heading: '',
    text: '',
    theme: 'dark',
    centered: false,
    animate: true,
    videoPlayCount: 'once',
    verticalTextAlign: 'center',
    darkVideo: false,
  }

  componentDidMount() {
    this.animate();

    if (this.props.animate) {
      this.t.play();
    }
  }

  animate = () => {
    const contentItems = this.content.childNodes;
    const bg = this.poster;

    this.t = new TimelineLite({ paused: true });
    this.t.addLabel('start');
    this.t.fromTo(this.heroInner, 1, { autoAlpha: 0 }, { autoAlpha: 1 });

    if (this.playButton) {
      this.t.fromTo(this.playButton, 1, { scale: 0.4, y: 40 }, { scale: 1, y: 0 }, 'start');
    }

    if (contentItems.length > 0) {
      this.t.staggerFromTo(contentItems, 1, { autoAlpha: 0, y: 40 }, { autoAlpha: 1, y: 0 }, 0.2, 'start');
    }

    if (bg) {
      this.t.fromTo(bg, 1, { scale: 1.1 }, { scale: 1 }, 'start');
      this.t.fromTo(bg, 0.6, { autoAlpha: 0 }, { autoAlpha: 1 }, 'start');
    }

    this.t.call(() => {
      this.setState({ isPlaying: true });
    });
  }

  componentDidUpdate(prevProps) {
    // prop sent in via loading component
    if (prevProps.animate !== this.props.animate && this.props.animate) {
      // eslint-disable-next-line react/prop-types
      this.t.play();
    }
  }

  // video-specific event
  togglePlay = () => {
    this.setState({ modalOpen: !this.state.modalOpen });

    // Normally we would have the component itself handle playback
    // However, we're playing a video with sound, so it has to be done in response to a user
    // interaction
    this.videoModalRef.current.playVideo();
  }

  render() {
    const {
      children,
      heading,
      text,
      image,
      videoFile,
      videoBg,
      poster,
      cta,
      headingWidth,
      textWidth,
      centered,
      theme,
      circles,
      videoPlayCount,
      verticalTextAlign,
      darkVideo,
    } = this.props;

    const { isReady, modalOpen, isPlaying } = this.state;

    return (
      <div className={s('hero', theme, `textalign_${verticalTextAlign}`, { centered, [s.hasVideo]: videoFile })}>
        <div className={s.hero__inner} ref={(el) => { this.heroInner = el; }} >
          <div className={s.hero__contentWrap}>
            <Container>
              <Row>
                <div className={s.hero__content} ref={(el) => { this.content = el; }}>
                  {(!videoBg && image && image.src) && (
                    <div className={s.hero__imageWrap}>
                      <div className={s.hero__image}>
                        <Image
                          src={image.src}
                          alt={image.alt}
                          width={image.width}
                          height={image.height}
                        />
                      </div>
                    </div>
                  )}
                  <h1 className={s.hero__heading} style={{ maxWidth: headingWidth }}>{heading}</h1>
                  {text && (<p className={s.hero__text} style={{ maxWidth: textWidth }}>{text}</p>)}
                  {cta && (
                    <div className={s.hero__cta}>
                      <Button color="transparent" to={cta.url} icon={cta.icon}>{cta.text}</Button>
                    </div>
                  )}
                  {children}
                </div>
                {videoFile && (
                  <div className={s.hero__playButton} ref={(el) => { this.playButton = el; }}>
                    <PlayButton visible={isReady} onClick={this.togglePlay} theme={theme} />
                  </div>
                )}
              </Row>
            </Container>
          </div>

          {videoBg && (
            <HeroVideo
              src={videoBg}
              poster={poster}
              isPlaying={isPlaying}
              playCount={videoPlayCount}
            />
          )}

          {(!videoBg && poster) && (
            <div
              ref={(el) => { this.poster = el; }}
              className={s.hero__background}
              style={{ backgroundImage: `url('${poster}')` }}
            >
              {children}
            </div>
          )}

          {circles === 'Enable' && (
            <Circles theme="dark" />
          )}

          {videoFile && (
            <VideoModal
              ref={this.videoModalRef}
              video={videoFile}
              open={modalOpen}
              onClose={() => this.setState({ modalOpen: false })}
              dark={darkVideo}
            />
          )}
        </div>
      </div>
    );
  }
}