import { graphql } from 'gatsby';
import Contact from 'routes/contact/Contact';

export default Contact;

export const query = graphql`
query {
    page: contentfulPageContact {
      pageTitle
      metaTitle
      metaImage {
        ...image
      }
      metaDescription
      heroTitle
      heroTheme
      heroImage {
          ...image
      }
      heroText : heroBody
      contactBlocks {
        ...on ContentfulContentBlock {
          title
          childContentfulContentBlockContentRichTextNode {
            childContentfulRichText {
              html
            }
          }
          content {
            internal {
              content
            }
          }
        }
      }
      addresses {
        ...on ContentfulAddress {
          name
          address1
          city
          state
        }
      }
    }
  }
`;
