import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TimelineLite } from 'gsap'

import Image from 'components/image'
import ViewportEnter from 'components/viewport-enter'

import s from './OfficesMap.scss'

export default class OfficesMap extends Component {
  state = {
    active: false,
  }

  static propTypes = {
    image: PropTypes.string,
    // width & height as image.width if we are adding this to contentful?
    // wil then need to add coords for map points :/
  }

  componentDidMount() {
    this.animate()
  }

  animate = () => {
    this.intro = new TimelineLite({ paused: true, onComplete: this.playLoop })
    this.intro.staggerFromTo(
      `.${s.officesMap__point}`,
      0.7,
      { autoAlpha: 0 },
      { autoAlpha: 1 },
      0.2
    )

    this.loop = new TimelineLite({ paused: true, onComplete: this.playLoop })
    this.loop.addLabel('start')

    this.loop.to(`.${s.officesMap__pointOuter}`, 1, { autoAlpha: 0 }, 'start')
    this.loop.to(
      `.${s.officesMap__pointOuter}`,
      1,
      { autoAlpha: 1 },
      'start+=1'
    )
    this.loop.to(
      `.${s.officesMap__pointInner}`,
      1,
      { autoAlpha: 0 },
      'start+=0.2'
    )
    this.loop.to(
      `.${s.officesMap__pointInner}`,
      1,
      { autoAlpha: 1 },
      'start+=1.2'
    )
    this.loop.addLabel('end')
  }

  onEnter = () => {
    this.setState({
      active: true,
    })

    this.intro.play()
  }

  onExit = () => {
    this.loop.seek('end').pause()
  }

  playLoop = () => {
    this.loop.seek(0).play()
  }

  render() {
    const { image } = this.props
    const { active } = this.state
    let i
    const points = []

    for (i = 0; i < 5; i++) {
      points.push(
        <div
          className={s.officesMap__point}
          key={`${s.officesMap__point}-${i}`}
        >
          <span className={s.officesMap__pointOuter} />
          <span className={s.officesMap__pointInner} />
        </div>
      )
    }

    return (
      <ViewportEnter
        onEnter={this.onEnter}
        onExit={this.onExit}
        threshold={0.4}
      >
        <div className={s('officesMap', { active })}>
          <div className={s.officesMap__inner}>
            <div className={s.officesMap__ratio} />
            <div className={s.officesMap__points}>{points}</div>
            <div className={s.officesMap__image}>
              <Image src={image} />
            </div>
          </div>
        </div>
      </ViewportEnter>
    )
  }
}
