import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

import s from './PlayButton.scss';

export default class PlayButton extends Component {

  static propTypes = {
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(['light', 'dark']), // needs context/ui
    visible: PropTypes.bool,
    isPaused: PropTypes.bool,
  }

  static defaultProps = {
    onClick: () => null,
    theme: 'dark',
    visible: false,
    isPaused: true,
  }

  onClick = () => {
    this.props.onClick();
  }

  render() {
    const { theme, visible, isPaused } = this.props;
    const id = isPaused ? 'play' : 'pause';

    return (
      <button className={s('playButton', theme, { visible })} onClick={this.onClick}>
        <Icon id={id} className={s.playButton__icon} />
      </button>
    );
  }
}

